import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  MenuItem,
  IconButton,
  Alert,
  CircularProgress,
  useTheme,
  alpha,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Project, Jurisdiction } from '../types';
import { projectAPI, jurisdictionAPI } from '../apis';
import { styled } from '@mui/material/styles';

const PROJECT_TYPES = [
  'Commercial',
  'Residential',
  'Industrial',
  'Infrastructure',
  'Mixed-Use',
  'Other'
];

const steps = ['Basic Information', 'Location Details', 'Review & Submit'];

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    transition: 'all 0.2s ease',
    backgroundColor: alpha(theme.palette.background.paper, 0.8),
    '&:hover': {
      backgroundColor: alpha(theme.palette.background.paper, 0.95),
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
      backgroundColor: alpha(theme.palette.background.paper, 1),
    },
  },
  '& .MuiInputLabel-root': {
    '&.Mui-focused': {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 24,
  boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
  background: `linear-gradient(135deg, ${alpha(theme.palette.background.paper, 0.9)} 0%, ${alpha(theme.palette.background.paper, 0.8)} 100%)`,
  backdropFilter: 'blur(10px)',
  border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    fontSize: '0.9rem',
    fontWeight: 500,
    '&.Mui-active': {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
      fontWeight: 600,
    },
  },
  '& .MuiStepIcon-root': {
    width: 32,
    height: 32,
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
    '&.Mui-completed': {
      color: theme.palette.success.main,
    },
  },
}));

const NewProject: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [jurisdictions, setJurisdictions] = useState<Jurisdiction[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<Partial<Project>>({
    name: '',
    description: '',
    address: '',
    parcelNumber: '',
    projectType: '',
    jurisdictionID: '',
    estimatedCost: undefined,
    status: 'pending',
  });

  useEffect(() => {
    const fetchJurisdictions = async () => {
      try {
        const response = await jurisdictionAPI.getJurisdictions();
        if (response.status === 'success' && response.data) {
          setJurisdictions(response.data);
        }
      } catch (error) {
        console.error('Error fetching jurisdictions:', error);
        setError('Failed to load jurisdictions. Please try again later.');
      }
    };

    fetchJurisdictions();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: name === 'estimatedCost' ? (value === '' ? undefined : Number(value)) : value
    }));
  };

  const isStepComplete = (step: number) => {
    switch (step) {
      case 0:
        return !!formData.name && !!formData.description && !!formData.projectType && !!formData.estimatedCost;
      case 1:
        return !!formData.address && !!formData.parcelNumber && !!formData.jurisdictionID;
      case 2:
        return true;
      default:
        return false;
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await projectAPI.createProject(formData);
      
      if (response.status === 'success' && response.data) {
        navigate('/dashboard');
      } else {
        throw new Error(response.message || 'Failed to create project');
      }
    } catch (error: any) {
      console.error('Error creating project:', error);
      if (error.status === 401) {
        navigate('/login', { state: { from: '/new-project' } });
      } else {
        setError(error.message || 'Failed to create project. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  label="Project Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  multiline
                  rows={4}
                  label="Project Description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  placeholder="Enter a detailed description of your project..."
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  select
                  fullWidth
                  required
                  label="Project Type"
                  name="projectType"
                  value={formData.projectType}
                  onChange={handleInputChange}
                >
                  {PROJECT_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTextField
                  fullWidth
                  required
                  type="number"
                  label="Estimated Cost"
                  name="estimatedCost"
                  value={formData.estimatedCost}
                  onChange={handleInputChange}
                  placeholder="Enter estimated cost"
                  InputProps={{
                    startAdornment: <Typography sx={{ mr: 1, color: 'text.secondary' }}>$</Typography>,
                  }}
                />
              </Grid>
            </Grid>
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  required
                  label="Parcel Number"
                  name="parcelNumber"
                  value={formData.parcelNumber}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12}>
                <StyledTextField
                  select
                  fullWidth
                  required
                  label="Jurisdiction"
                  name="jurisdictionID"
                  value={formData.jurisdictionID}
                  onChange={handleInputChange}
                >
                  {jurisdictions.map((jurisdiction) => (
                    <MenuItem key={jurisdiction.jurisdictionID} value={jurisdiction.jurisdictionID}>
                      {jurisdiction.name} ({jurisdiction.city}, {jurisdiction.state})
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
            </Grid>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 3, 
                    bgcolor: alpha(theme.palette.primary.main, 0.03),
                    borderRadius: 3,
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  }}
                >
                  <Typography 
                    variant="h6" 
                    gutterBottom 
                    sx={{ 
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                      mb: 2,
                    }}
                  >
                    Review Project Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Box 
                        sx={{ 
                          p: 2,
                          height: '100%',
                          borderRadius: 2,
                          bgcolor: alpha(theme.palette.background.paper, 0.5),
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            bgcolor: alpha(theme.palette.background.paper, 0.8),
                            transform: 'translateY(-2px)',
                          },
                        }}
                      >
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 0.5 }}>Project Name</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.name || 'Not specified'}</Typography>
                        </Box>
                        <Box>
                          <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 0.5 }}>Project Type</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.projectType || 'Not specified'}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box 
                        sx={{ 
                          p: 2,
                          height: '100%',
                          borderRadius: 2,
                          bgcolor: alpha(theme.palette.background.paper, 0.5),
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            bgcolor: alpha(theme.palette.background.paper, 0.8),
                            transform: 'translateY(-2px)',
                          },
                        }}
                      >
                        <Box sx={{ mb: 2 }}>
                          <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 0.5 }}>Address</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.address || 'Not specified'}</Typography>
                        </Box>
                        <Box>
                          <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 0.5 }}>Parcel Number</Typography>
                          <Typography variant="body1" fontWeight={500}>{formData.parcelNumber || 'Not specified'}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box 
                        sx={{ 
                          p: 2,
                          height: '100%',
                          borderRadius: 2,
                          bgcolor: alpha(theme.palette.background.paper, 0.5),
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            bgcolor: alpha(theme.palette.background.paper, 0.8),
                            transform: 'translateY(-2px)',
                          },
                        }}
                      >
                        <Box>
                          <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 0.5 }}>Jurisdiction</Typography>
                          <Typography variant="body1" fontWeight={500}>
                            {jurisdictions.find(j => j.jurisdictionID === formData.jurisdictionID)?.name || 'Not specified'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box 
                        sx={{ 
                          p: 2,
                          height: '100%',
                          borderRadius: 2,
                          bgcolor: alpha(theme.palette.background.paper, 0.5),
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            bgcolor: alpha(theme.palette.background.paper, 0.8),
                            transform: 'translateY(-2px)',
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 0.5 }}>Estimated Cost</Typography>
                          <Typography variant="h6" fontWeight={600} color="primary.main">
                            ${Number(formData.estimatedCost).toLocaleString() || 'Not specified'}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: 'background.default',
        minHeight: '100vh',
        pt: '84px',
        pb: 4,
        background: `linear-gradient(135deg, ${alpha(theme.palette.primary.main, 0.05)} 0%, ${alpha(theme.palette.background.default, 1)} 100%)`,
      }}
    >
      <Container maxWidth="md">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: alpha(theme.palette.background.paper, 0.8),
                boxShadow: `0 2px 12px ${alpha(theme.palette.primary.main, 0.1)}`,
                backdropFilter: 'blur(10px)',
                '&:hover': {
                  bgcolor: theme.palette.background.paper,
                  transform: 'scale(1.1)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography 
              variant="h4" 
              sx={{ 
                fontWeight: 700,
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                letterSpacing: '-0.5px',
              }}
            >
              Create New Project
            </Typography>
          </Box>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 3,
                borderRadius: 3,
                boxShadow: `0 4px 12px ${alpha(theme.palette.error.main, 0.1)}`,
                border: `1px solid ${alpha(theme.palette.error.main, 0.1)}`,
              }}
            >
              {error}
            </Alert>
          )}

          <StyledPaper>
            <Stepper 
              activeStep={activeStep} 
              sx={{ 
                mb: 4,
                '& .MuiStepConnector-line': {
                  minHeight: 2,
                },
                '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
                  borderColor: theme.palette.primary.main,
                },
                '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
                  borderColor: theme.palette.success.main,
                },
              }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StyledStepLabel>{label}</StyledStepLabel>
                </Step>
              ))}
            </Stepper>

            <Box sx={{ mt: 4 }}>
              {renderStepContent(activeStep)}
            </Box>

            <Divider sx={{ my: 4, borderColor: alpha(theme.palette.primary.main, 0.1) }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                onClick={handleBack}
                disabled={activeStep === 0 || loading}
                sx={{
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                  color: theme.palette.text.secondary,
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                  },
                }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!isStepComplete(activeStep) || loading}
                sx={{
                  px: 4,
                  py: 1.5,
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem',
                  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
                  boxShadow: `0 8px 16px ${alpha(theme.palette.primary.main, 0.2)}`,
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: `0 12px 20px ${alpha(theme.palette.primary.main, 0.3)}`,
                  },
                  transition: 'all 0.2s ease',
                }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : activeStep === steps.length - 1 ? (
                  'Create Project'
                ) : (
                  'Next'
                )}
              </Button>
            </Box>
          </StyledPaper>
        </motion.div>
      </Container>
    </Box>
  );
};

export default NewProject; 