import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Chip,
  useTheme,
  alpha,
  CircularProgress,
  IconButton,
  Tooltip,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import {
  Add as AddIcon,
  Visibility as VisibilityIcon,
  Download as DownloadIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import AuthenticatedNavbar from '../components/AuthenticatedNavbar';
import { Permit } from '../types';
import { permitAPI } from '../apis';

const MyPermits: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [permits, setPermits] = useState<Permit[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [permitToDelete, setPermitToDelete] = useState<string | null>(null);

  useEffect(() => {
    const fetchPermits = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await permitAPI.getPermits();
        
        if (response.status === 'success' && response.data) {
          setPermits(response.data);
        } else {
          throw new Error(response.message || 'Failed to fetch permits');
        }
      } catch (error) {
        console.error('Error fetching permits:', error);
        setError('Failed to load permits. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPermits();
  }, []);

  const handleDownloadReport = async (permitId: string) => {
    try {
      setError(null);
      const response = await permitAPI.getPermitReport(permitId);
      
      if (response.status === 'success' && response.data) {
        navigate(`/permit/${permitId}/report`);
      } else {
        throw new Error(response.message || 'Failed to fetch report');
      }
    } catch (error) {
      console.error('Error downloading report:', error);
      setError('Failed to download report. Please try again later.');
    }
  };

  const handleDeleteClick = (permitId: string) => {
    setPermitToDelete(permitId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!permitToDelete) return;

    try {
      setError(null);
      const response = await permitAPI.deletePermit(permitToDelete);
      
      if (response.status === 'success') {
        setPermits(prevPermits => prevPermits.filter(p => p.permitID !== permitToDelete));
        setDeleteDialogOpen(false);
        setPermitToDelete(null);
      } else {
        throw new Error(response.message || 'Failed to delete permit');
      }
    } catch (error) {
      console.error('Error deleting permit:', error);
      setError('Failed to delete permit. Please try again later.');
    }
  };

  const getStatusColor = (status: string): 'success' | 'error' | 'warning' | 'info' => {
    switch (status.toLowerCase()) {
      case 'approved':
        return 'success';
      case 'rejected':
        return 'error';
      case 'in_review':
        return 'info';
      default:
        return 'warning';
    }
  };

  const formatStatus = (status: string): string => {
    return status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
  };

  if (loading) {
    return (
      <>
        <AuthenticatedNavbar />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            pt: '84px',
          }}
        >
          <CircularProgress />
        </Box>
      </>
    );
  }

  return (
    <>
      <AuthenticatedNavbar />
      <Box
        component="main"
        sx={{
          pt: '84px',
          minHeight: '100vh',
          bgcolor: 'background.default',
        }}
      >
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
            <Typography variant="h4">My Permits</Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('/new-permit')}
              sx={{
                bgcolor: theme.palette.primary.main,
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.9),
                },
              }}
            >
              New Permit
            </Button>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={3}>
            {permits.length === 0 ? (
              <Grid item xs={12}>
                <Paper sx={{ p: 4, textAlign: 'center' }}>
                  <Typography variant="h6" color="text.secondary" gutterBottom>
                    No permits found
                  </Typography>
                  <Typography variant="body2" color="text.secondary" paragraph>
                    Start by creating a new permit application
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => navigate('/new-permit')}
                  >
                    Create Permit
                  </Button>
                </Paper>
              </Grid>
            ) :
              permits.map((permit) => (
                <Grid item xs={12} key={permit.permitID}>
                  <Paper
                    sx={{
                      p: 3,
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: theme.shadows[4],
                      },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <Typography variant="h6">Permit {permit.permitID}</Typography>
                          <Chip
                            label={formatStatus(permit.status)}
                            color={getStatusColor(permit.status)}
                            size="small"
                          />
                        </Box>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Permit ID: {permit.permitID}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Type: {permit.permitType}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Stage: {permit.currentReviewStage}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Submitted: {new Date(permit.submissionDate).toLocaleDateString()}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                          <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Project Details
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Project ID: {permit.projectID}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Timeline: {permit.estimatedTimeline}
                            </Typography>
                            {permit.approvalDate && (
                              <Typography
                                variant="body2"
                                sx={{
                                  color: theme.palette.success.main,
                                  fontWeight: 500,
                                }}
                              >
                                Approved: {new Date(permit.approvalDate).toLocaleDateString()}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
                            <Tooltip title="View Details">
                              <IconButton
                                onClick={() => navigate(`/permit/${permit.permitID}`)}
                                sx={{ color: theme.palette.primary.main }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Download Report">
                              <IconButton
                                onClick={() => handleDownloadReport(permit.permitID)}
                                sx={{ color: theme.palette.info.main }}
                              >
                                <DownloadIcon />
                              </IconButton>
                            </Tooltip>
                            {permit.status !== 'approved' && (
                              <>
                                <Tooltip title="Edit Permit">
                                  <IconButton
                                    onClick={() => navigate(`/permit/${permit.permitID}/edit`)}
                                    sx={{ color: theme.palette.warning.main }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete Permit">
                                  <IconButton
                                    onClick={() => handleDeleteClick(permit.permitID)}
                                    sx={{ color: theme.palette.error.main }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              ))
            }
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-dialog-title"
      >
        <DialogTitle id="delete-dialog-title">Delete Permit</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this permit? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyPermits; 