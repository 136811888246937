import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  ListItemButton,
  Typography,
  Fab,
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
  Slide,
  Paper,
  keyframes
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useLocation } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { authAPI, chatAPI, projectAPI, permitAPI } from '../apis';

const drawerWidth = 240;
const collapsedDrawerWidth = 72;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  width: open ? drawerWidth : collapsedDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth : collapsedDrawerWidth,
    boxSizing: 'border-box',
    borderRight: 'none',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Logo = styled(Typography)(() => ({
  color: '#1E4C9A',
  fontWeight: 'bold',
  fontSize: '24px',
  padding: '20px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const StyledFab = styled(Fab)({
  position: 'fixed',
  bottom: 24,
  right: 24,
  backgroundColor: '#D35400',
  '&:hover': {
    backgroundColor: '#A04000',
  },
  zIndex: 1000,
});

const ChatWindow = styled(Paper)({
  position: 'fixed',
  bottom: 84,
  right: 24,
  width: '450px',
  height: '520px',
  borderRadius: '12px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 999,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
});

const ChatHeader = styled(Paper)({
  padding: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid #eee',
  backgroundColor: '#fff',
});

const ChatMessages = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  backgroundColor: '#F8F9FA',
});

const ChatInputWrapper = styled(Paper)({
  padding: '12px',
  borderTop: '1px solid #eee',
  backgroundColor: '#fff',
});

const bounce = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
`;

const TypingBubble = styled(Box)({
  display: 'flex',
  gap: '4px',
  padding: '12px 16px',
  backgroundColor: '#fff',
  borderRadius: '12px',
  alignSelf: 'flex-start',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  '& .dot': {
    width: '6px',
    height: '6px',
    backgroundColor: '#90C17D',
    borderRadius: '50%',
    animation: `${bounce} 1s infinite`,
    '&:nth-of-type(2)': {
      animationDelay: '0.2s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '0.4s',
    },
  },
});

const StyledList = styled(List)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  '& > *': {
    flex: 1,
  },
});

const StyledListItemButton = styled(ListItemButton)({
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: 'rgba(30, 76, 154, 0.08)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(30, 76, 154, 0.12)',
    '& .MuiListItemIcon-root': {
      color: '#1E4C9A',
    },
    '& .MuiListItemText-primary': {
      color: '#1E4C9A',
      fontWeight: 600,
    },
    '&:hover': {
      backgroundColor: 'rgba(30, 76, 154, 0.16)',
    },
  },
});

const menuItems = [
  { text: 'Overview', icon: <DashboardIcon />, path: '/dashboard' },
  { text: 'Projects', icon: <FolderIcon />, path: '/projects' },
  { text: 'Documents', icon: <DescriptionIcon />, path: '/documents' },
  { text: 'Approvals', icon: <CheckCircleIcon />, path: '/approvals' },
  { text: 'History', icon: <HistoryIcon />, path: '/history' },
  { text: 'Profile', icon: <PersonIcon />, path: '/profile' },
  { text: 'Settings', icon: <SettingsIcon />, path: '/settings' },
  { text: 'Saru Beta', icon: <SmartToyIcon />, path: '/saru' },
];

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface APIFunction {
  name: string;
  description: string;
  parameters: {
    name: string;
    type: string;
    required: boolean;
    description: string;
    options?: string[];
  }[];
  api: (...args: any[]) => Promise<any>;
}

const API_FUNCTIONS: { [key: string]: APIFunction } = {
  'Get Projects': {
    name: 'getProjects',
    description: 'Get all projects',
    parameters: [],
    api: projectAPI.getProjects
  },
  'Get Project': {
    name: 'getProject',
    description: 'Get a specific project by ID',
    parameters: [
      {
        name: 'projectId',
        type: 'string',
        required: true,
        description: 'The ID of the project to retrieve'
      }
    ],
    api: projectAPI.getProject
  },
  'Create Project': {
    name: 'createProject',
    description: 'Create a new project',
    parameters: [
      {
        name: 'name',
        type: 'string',
        required: true,
        description: 'Project name'
      },
      {
        name: 'description',
        type: 'string',
        required: true,
        description: 'Project description'
      },
      {
        name: 'projectType',
        type: 'string',
        required: true,
        description: 'Type of project',
        options: ['Commercial', 'Residential', 'Industrial', 'Infrastructure', 'Mixed-Use', 'Other']
      },
      {
        name: 'jurisdictionID',
        type: 'string',
        required: true,
        description: 'ID of the jurisdiction'
      },
      {
        name: 'estimatedCost',
        type: 'number',
        required: true,
        description: 'Estimated cost of the project'
      }
    ],
    api: projectAPI.createProject
  },
  'Get Permits': {
    name: 'getPermits',
    description: 'Get all permits',
    parameters: [],
    api: permitAPI.getPermits
  },
  'Create Permit': {
    name: 'createPermit',
    description: 'Create a new permit',
    parameters: [
      {
        name: 'permitType',
        type: 'string',
        required: true,
        description: 'Type of permit',
        options: ['Building Permit', 'Electrical Permit', 'Plumbing Permit', 'Mechanical Permit', 'Demolition Permit', 'Grading Permit', 'Other']
      },
      {
        name: 'projectID',
        type: 'string',
        required: true,
        description: 'ID of the project'
      },
      {
        name: 'estimatedTimeline',
        type: 'string',
        required: true,
        description: 'Estimated timeline for the permit'
      },
      {
        name: 'files',
        type: 'files',
        required: true,
        description: 'Documents to attach to the permit'
      }
    ],
    api: permitAPI.createPermit
  },
  'Run Compliance Check': {
    name: 'runComplianceCheck',
    description: 'Run a compliance check for a project',
    parameters: [
      {
        name: 'projectId',
        type: 'string',
        required: true,
        description: 'The ID of the project to check'
      }
    ],
    api: projectAPI.runComplianceCheck
  }
};

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [messages, setMessages] = useState<Message[]>([
    {
      role: 'assistant',
      content: "Hi! I'm Saru, your AI assistant. How can I help you today?"
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState<APIFunction | null>(null);
  const [functionParams, setFunctionParams] = useState<{[key: string]: any}>({});
  const [functionResult, setFunctionResult] = useState<any>(null);

  const handleLogout = async () => {
    try {
      await authAPI.logout();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
      localStorage.removeItem('isAuthenticated');
      navigate('/login');
    }
  };

  const scrollToMessage = (element: HTMLElement | null) => {
    if (element) {
      element.scrollIntoView({ 
        behavior: "smooth",
        block: "start"
      });
    }
  };

  const handleFunctionSelect = async (functionName: string) => {
    const func = API_FUNCTIONS[functionName];
    if (!func) {
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: `I'm sorry, I don't know how to handle the function "${functionName}".`
      }]);
      return;
    }

    setSelectedFunction(func);
    setFunctionParams({});
    
    if (func.parameters.length === 0) {
      try {
        const result = await func.api();
        setFunctionResult(result);
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: `Here's the result of ${func.name}:\n\n${JSON.stringify(result, null, 2)}`
        }]);
      } catch (error) {
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: `I encountered an error while executing ${func.name}: ${error instanceof Error ? error.message : 'Unknown error'}`
        }]);
      }
    } else {
      const paramPrompt = `I'll help you with ${func.name}. I need the following information:\n\n` +
        func.parameters.map(p => `- ${p.name}${p.required ? ' (required)' : ''}: ${p.description}`).join('\n');
      
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: paramPrompt
      }]);
    }
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      const userMessage = { role: 'user' as const, content: message.trim() };
      setMessages(prev => [...prev, userMessage]);
      setMessage('');
      setIsLoading(true);

      try {
        if (selectedFunction) {
          const params = parseFunctionParams(message, selectedFunction);
          if (params.missing.length > 0) {
            setMessages(prev => [...prev, {
              role: 'assistant',
              content: `I still need the following parameters:\n\n${params.missing.map(p => `- ${p.name}: ${p.description}`).join('\n')}`
            }]);
          } else {
            try {
              const result = await selectedFunction.api(params.values);
              setFunctionResult(result);
              setMessages(prev => [...prev, {
                role: 'assistant',
                content: `Here's the result of ${selectedFunction.name}:\n\n${JSON.stringify(result, null, 2)}`
              }]);
              setSelectedFunction(null);
            } catch (error) {
              setMessages(prev => [...prev, {
                role: 'assistant',
                content: `I encountered an error while executing ${selectedFunction.name}: ${error instanceof Error ? error.message : 'Unknown error'}`
              }]);
            }
          }
        } else {
          const functionMatch = Object.entries(API_FUNCTIONS).find(([name]) => 
            message.toLowerCase().includes(name.toLowerCase())
          );

          if (functionMatch) {
            await handleFunctionSelect(functionMatch[0]);
          } else {
            const response = await chatAPI.sendMessage(userMessage.content);
            
            if (response.status === 'success' && response.message?.content) {
              setMessages(prev => [...prev, {
                role: 'assistant',
                content: response.message.content
              }]);
            } else {
              throw new Error(response.error || 'Invalid response format');
            }
          }
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: "I'm sorry, I encountered an error. Please try again later."
        }]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const parseFunctionParams = (message: string, func: APIFunction) => {
    const values: {[key: string]: any} = {};
    const missing: typeof func.parameters = [];

    func.parameters.forEach(param => {
      const regex = new RegExp(`${param.name}[:\\s]+(.*?)(?=\\s+\\w+[:\\s]|$)`, 'i');
      const match = message.match(regex);

      if (match) {
        const value = match[1].trim();
        if (param.type === 'number') {
          values[param.name] = parseFloat(value);
        } else {
          values[param.name] = value;
        }
      } else if (param.required) {
        missing.push(param);
      }
    });

    return { values, missing };
  };

  useEffect(() => {
    if (messages.length > 0) {
      const messageElements = document.querySelectorAll('[data-message-role]');
      const lastMessage = messageElements[messageElements.length - 1];
      
      if (lastMessage?.getAttribute('data-message-role') === 'assistant') {
        scrollToMessage(lastMessage as HTMLElement);
      }
    }
  }, [messages]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <StyledDrawer variant="permanent" open={isDrawerOpen}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: isDrawerOpen ? 'space-between' : 'center',
          pr: isDrawerOpen ? 2 : 0
        }}>
          {isDrawerOpen && <Logo>Augence</Logo>}
          <IconButton onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
            <MenuIcon />
          </IconButton>
        </Box>
        <StyledList>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <StyledListItemButton
                selected={location.pathname === item.path}
                onClick={() => navigate(item.path)}
                sx={{
                  minHeight: 48,
                  justifyContent: isDrawerOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon sx={{ 
                  minWidth: 0,
                  mr: isDrawerOpen ? 2 : 'auto',
                  justifyContent: 'center',
                  color: location.pathname === item.path ? '#1E4C9A' : 'inherit',
                }}>
                  {item.icon}
                </ListItemIcon>
                {isDrawerOpen && (
                  <ListItemText 
                    primary={item.text} 
                    sx={{ 
                      opacity: isDrawerOpen ? 1 : 0,
                      '& .MuiTypography-root': {
                        fontWeight: location.pathname === item.path ? 600 : 400,
                        color: location.pathname === item.path ? '#1E4C9A' : 'inherit',
                      }
                    }}
                  />
                )}
              </StyledListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding sx={{ mt: 'auto' }}>
            <StyledListItemButton 
              onClick={handleLogout}
              sx={{
                minHeight: 48,
                justifyContent: isDrawerOpen ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon sx={{ 
                minWidth: 0,
                mr: isDrawerOpen ? 2 : 'auto',
                justifyContent: 'center',
              }}>
                <LogoutIcon />
              </ListItemIcon>
              {isDrawerOpen && <ListItemText primary="Logout" />}
            </StyledListItemButton>
          </ListItem>
        </StyledList>
      </StyledDrawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#F8F9FA',
          minHeight: '100vh',
          position: 'relative',
          transition: (theme) => theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          ml: 0,
          width: `calc(100% - ${collapsedDrawerWidth}px)`,
          ...(isDrawerOpen && {
            width: `calc(100% - ${drawerWidth}px)`,
          }),
        }}
      >
        {children}
      </Box>

      {/* AI Chat Button and Window */}
      <StyledFab 
        color="primary" 
        aria-label="chat"
        onClick={() => setIsChatOpen(!isChatOpen)}
        sx={{
          transform: isChatOpen ? 'rotate(90deg)' : 'none',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        {isChatOpen ? <CloseIcon /> : <SmartToyIcon />}
      </StyledFab>

      {isChatOpen && (
        <ChatWindow>
          <ChatHeader elevation={0}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SmartToyIcon sx={{ color: '#D35400' }} />
              <Box>
                <Typography variant="subtitle1" fontWeight={500}>Saru</Typography>
                <Typography variant="caption" color="text.secondary">AI Assistant</Typography>
              </Box>
            </Box>
            <IconButton 
              size="small" 
              onClick={() => setIsChatOpen(false)}
              sx={{ color: 'text.secondary' }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </ChatHeader>

          <ChatMessages>
            {messages.map((msg, index) => (
              <Box 
                key={index}
                data-message-role={msg.role}
                sx={{ 
                  p: 2, 
                  bgcolor: '#fff', 
                  borderRadius: '12px',
                  alignSelf: msg.role === 'user' ? 'flex-end' : 'flex-start',
                  maxWidth: '80%',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  ...(msg.role === 'user' && {
                    bgcolor: '#1E4C9A',
                    color: 'white',
                  })
                }}
              >
                <Typography>
                  {msg.content}
                </Typography>
              </Box>
            ))}
            {isLoading && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2, ml: 2 }}>
                <TypingBubble>
                  <span className="dot" />
                  <span className="dot" />
                  <span className="dot" />
                </TypingBubble>
              </Box>
            )}
          </ChatMessages>

          <ChatInputWrapper elevation={0}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                fullWidth
                multiline
                maxRows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder="Type your message..."
                variant="outlined"
                size="small"
                disabled={isLoading}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '8px',
                    backgroundColor: '#F8F9FA',
                  }
                }}
              />
              <IconButton
                onClick={handleSendMessage}
                disabled={!message.trim() || isLoading}
                sx={{
                  bgcolor: '#90C17D',
                  color: 'white',
                  width: 40,
                  height: 40,
                  borderRadius: '8px',
                  '&:hover': {
                    bgcolor: '#7BA668',
                  },
                  '&.Mui-disabled': {
                    bgcolor: '#E2E8F0',
                    color: '#A0AEC0',
                  }
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </ChatInputWrapper>
        </ChatWindow>
      )}
    </Box>
  );
};

export default Layout; 