import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Alert,
  SelectChangeEvent,
  Paper,
  InputLabel,
  useTheme,
  alpha,
  Stack,
  Fade,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
} from '@mui/material';
import { Visibility, VisibilityOff, ArrowForward, ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '../apis';
import { User } from '../types';

type SignUpForm = Omit<User, 'userID'> & {
  password: string;
  confirmPassword: string;
};

const USER_TYPES = ['developer', 'government'];
const LANGUAGES = ['English', 'Spanish', 'French', 'Chinese', 'Arabic'];

const STEPS = ['Basic Information', 'Additional Details'];

const SignUp: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState<SignUpForm>({
    userType: '',
    email: '',
    phone: '',
    username: '',
    organization: '',
    password: '',
    confirmPassword: '',
    preferredLanguage: 'English',
    notificationPreferences: ['email', 'sms'],
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);

  useEffect(() => {
    if (formData.confirmPassword && formData.password !== formData.confirmPassword) {
      setPasswordError('Passwords do not match');
    } else {
      setPasswordError(null);
    }
  }, [formData.password, formData.confirmPassword]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const validateStep = (step: number) => {
    if (step === 0) {
      if (!formData.email || !formData.password || !formData.confirmPassword || !formData.username) {
        setError('Please fill in all required fields');
        return false;
      }
      if (formData.password !== formData.confirmPassword) {
        setError('Passwords do not match');
        return false;
      }
    } else if (step === 1) {
      if (!formData.userType || !formData.phone || !formData.organization) {
        setError('Please fill in all required fields');
        return false;
      }
      if (!termsAccepted) {
        setError('Please accept the Terms of Use and Privacy Policy');
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      setError(null);
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setError(null);
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateStep(activeStep)) {
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      const response = await authAPI.signup({
        userType: formData.userType,
        email: formData.email,
        phone: formData.phone,
        username: formData.username,
        organization: formData.organization,
        password: formData.password,
        preferredLanguage: formData.preferredLanguage,
        notificationPreferences: ['email', 'sms'],
        marketingConsent: marketingAccepted,
      });

      if (response.status === 'success') {
        navigate('/login');
      } else {
        throw new Error(response.message || 'Failed to create account');
      }
    } catch (error) {
      console.error('Signup error:', error);
      setError('Failed to create account. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Stack spacing={2.5}>
            <TextField
              fullWidth
              required
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              placeholder="Choose a username"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                },
              }}
            />

            <TextField
              fullWidth
              required
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter your email address"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                },
              }}
            />

            <TextField
              fullWidth
              required
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleInputChange}
              placeholder="Enter your password"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              fullWidth
              required
              label="Confirm Password"
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              value={formData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Re-enter your password"
              error={!!passwordError}
              helperText={passwordError}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                },
                '& .MuiFormHelperText-root': {
                  color: theme.palette.error.main,
                  marginLeft: 0,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        );
      case 1:
        return (
          <Stack spacing={2.5}>
            <FormControl>
              <InputLabel>Select your role</InputLabel>
              <Select
                value={formData.userType}
                onChange={handleSelectChange}
                name="userType"
                label="Select your role"
                sx={{
                  borderRadius: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                }}
              >
                {USER_TYPES.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              required
              label="Phone Number"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Enter your phone number"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                },
              }}
            />

            <TextField
              fullWidth
              required
              label="Organization"
              name="organization"
              value={formData.organization}
              onChange={handleInputChange}
              placeholder="Enter your organization name"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  '& fieldset': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                },
              }}
            />

            <FormControl>
              <InputLabel>Preferred Language</InputLabel>
              <Select
                value={formData.preferredLanguage}
                onChange={handleSelectChange}
                name="preferredLanguage"
                label="Preferred Language"
                sx={{
                  borderRadius: 2,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: alpha(theme.palette.primary.main, 0.2),
                  },
                }}
              >
                {LANGUAGES.map((language) => (
                  <MenuItem key={language} value={language}>
                    {language}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  sx={{
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  By creating an account, I agree to the{' '}
                  <Link href="/terms" sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link href="/privacy" sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={marketingAccepted}
                  onChange={(e) => setMarketingAccepted(e.target.checked)}
                  sx={{
                    color: theme.palette.primary.main,
                    '&.Mui-checked': {
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  I would like to receive product updates and marketing promotions
                </Typography>
              }
            />
          </Stack>
        );
      default:
        return null;
    }
  };

  const isStepValid = useMemo(() => {
    if (activeStep === 0) {
      return (
        formData.email.trim() !== '' &&
        formData.username.trim() !== '' &&
        formData.password.trim() !== '' &&
        formData.confirmPassword.trim() !== '' &&
        !passwordError
      );
    } else if (activeStep === 1) {
      return (
        formData.userType.trim() !== '' &&
        formData.phone.trim() !== '' &&
        formData.organization.trim() !== '' &&
        termsAccepted
      );
    }
    return false;
  }, [
    activeStep,
    formData.email,
    formData.username,
    formData.password,
    formData.confirmPassword,
    formData.userType,
    formData.phone,
    formData.organization,
    termsAccepted,
    passwordError
  ]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        bgcolor: 'background.default',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        py: 12,
      }}
    >
      <Container maxWidth="sm">
        <Paper
          elevation={2}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: 700,
              color: 'primary.main',
              textAlign: 'center',
              mb: 3,
            }}
          >
            Augence
          </Typography>

          <Typography 
            variant="h5" 
            gutterBottom
            sx={{ 
              fontWeight: 500,
              textAlign: 'center',
              mb: 3,
            }}
          >
            Create Account
          </Typography>

          <Stepper 
            activeStep={activeStep} 
            sx={{ 
              width: '100%',
              mb: 3 
            }}
          >
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 3,
                width: '100%'
              }}
            >
              {error}
            </Alert>
          )}

          <Box
            component="form"
            onSubmit={activeStep === STEPS.length - 1 ? handleSubmit : (e) => { e.preventDefault(); handleNext(); }}
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
            }}
          >
            <Fade in timeout={500}>
              <Box>
                {renderStepContent(activeStep)}

                <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                  {activeStep > 0 && (
                    <Button
                      variant="outlined"
                      onClick={handleBack}
                      startIcon={<ArrowBack />}
                      sx={{
                        flex: 1,
                        py: 1.5,
                        borderRadius: 1,
                        textTransform: 'none',
                        fontSize: '1rem',
                        fontWeight: 500,
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={loading || !isStepValid}
                    endIcon={activeStep === STEPS.length - 1 ? undefined : <ArrowForward />}
                    sx={{
                      flex: 1,
                      py: 1.5,
                      position: 'relative',
                      borderRadius: 1,
                      textTransform: 'none',
                      fontSize: '1rem',
                      fontWeight: 500,
                      opacity: (!isStepValid || loading) ? 0.7 : 1,
                    }}
                  >
                    {loading
                      ? 'Creating Account...'
                      : activeStep === STEPS.length - 1
                      ? 'Create Account'
                      : 'Next'}
                  </Button>
                </Stack>
              </Box>
            </Fade>
          </Box>

          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <Typography variant="body2">
              Already have an account?{' '}
              <Link
                href="/login"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/login');
                }}
                sx={{
                  color: 'text.secondary',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Log in
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SignUp; 