import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';
import Dashboard from '../pages/Dashboard';
import Home from '../pages/Home';
import NewPermit from '../pages/NewPermit';
import MyPermits from '../pages/MyPermits';
import Projects from '../pages/Projects';
import ProjectForm from '../pages/NewProject';
import ProjectDetails from '../pages/ProjectDetails';
import ComplianceReport from '../pages/ComplianceReport';
import Documents from '../pages/Documents';
import Approvals from '../pages/Approvals';
import History from '../pages/History';
import Profile from '../pages/Profile';
import Layout from '../components/Layout';
import Header from '../components/Header';
import SignUp from '../pages/SignUp';
import Saru from '../pages/Saru';
import { ProtectedRouteProps } from '../types';
import { isAuthenticated } from '../utils/auth';

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();

  if (!isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Layout>
      <Header />
      {children}
    </Layout>
  );
};

const AppRouter: React.FC = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/register" element={<SignUp />} />
      
      {/* Protected routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/projects"
        element={
          <ProtectedRoute>
            <Projects />
          </ProtectedRoute>
        }
      />
      <Route
        path="/documents"
        element={
          <ProtectedRoute>
            <Documents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/approvals"
        element={
          <ProtectedRoute>
            <Approvals />
          </ProtectedRoute>
        }
      />
      <Route
        path="/history"
        element={
          <ProtectedRoute>
            <History />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-project"
        element={
          <ProtectedRoute>
            <ProjectForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="/project/:projectId"
        element={
          <ProtectedRoute>
            <ProjectDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-permit"
        element={
          <ProtectedRoute>
            <NewPermit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/permits"
        element={
          <ProtectedRoute>
            <MyPermits />
          </ProtectedRoute>
        }
      />
      <Route
        path="/compliance-report/:permitId"
        element={
          <ProtectedRoute>
            <ComplianceReport />
          </ProtectedRoute>
        }
      />
      <Route
        path="/saru"
        element={
          <ProtectedRoute>
            <Saru />
          </ProtectedRoute>
        }
      />

      {/* Catch all route */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

export default AppRouter; 