import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1E4C9A', // Royal blue - representing professionalism and trust
      light: '#2E6BC7',
      dark: '#153670',
    },
    secondary: {
      main: '#D35400', // Burnt orange - representing energy and action
      light: '#E67E22',
      dark: '#A04000',
    },
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5', // Light gray background
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#4A4A4A',
    },
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2.75rem',
      fontWeight: 600,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.6,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '10px 24px',
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 4px 8px rgba(30, 76, 154, 0.2)', // Updated shadow color to match royal blue
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(30, 76, 154, 0.1)', // Updated shadow color to match royal blue
          },
        },
      },
    },
  },
});

export default theme; 