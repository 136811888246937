import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider,
} from '@mui/material';

const mockApprovals = [
  {
    id: 1,
    projectName: 'Commercial Building A',
    permitType: 'Building Permit',
    status: 'pending',
    submittedDate: '2024-03-15',
  },
  {
    id: 2,
    projectName: 'Residential Complex B',
    permitType: 'Electrical Permit',
    status: 'approved',
    submittedDate: '2024-03-14',
  },
  {
    id: 3,
    projectName: 'Office Renovation C',
    permitType: 'Plumbing Permit',
    status: 'rejected',
    submittedDate: '2024-03-13',
  },
];

const Approvals: React.FC = () => {
  return (
    <Box sx={{ pt: '84px', minHeight: '100vh', bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Approvals
        </Typography>

        <Paper sx={{ p: 3 }}>
          <List>
            {mockApprovals.map((approval, index) => (
              <React.Fragment key={approval.id}>
                <ListItem>
                  <ListItemText
                    primary={approval.projectName}
                    secondary={`${approval.permitType} • Submitted on ${new Date(approval.submittedDate).toLocaleDateString()}`}
                  />
                  <Chip
                    label={approval.status.toUpperCase()}
                    color={
                      approval.status === 'approved'
                        ? 'success'
                        : approval.status === 'rejected'
                        ? 'error'
                        : 'warning'
                    }
                  />
                </ListItem>
                {index < mockApprovals.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Container>
    </Box>
  );
};

export default Approvals; 