import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Collapse,
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Edit as EditIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

interface HistoryEvent {
  id: number;
  activity: string;
  dateTime: string;
  detail: string;
  hasDetails?: boolean;
}

interface ProjectHistory {
  projectId: string;
  projectName: string;
  events: HistoryEvent[];
}

const mockData: ProjectHistory[] = [
  {
    projectId: '1',
    projectName: 'Downtown Highrise',
    events: [
      {
        id: 1,
        activity: 'Project Created',
        dateTime: '03/01/2025, 10: 00 AM',
        detail: 'Project "Site A Development" initialized.',
        hasDetails: true,
      },
      {
        id: 2,
        activity: 'Document Uploaded',
        dateTime: '03/01/2025, 02: 15 PM',
        detail: 'Uploaded "Site Plan 2025.pdf" (v1.0).',
      },
      {
        id: 3,
        activity: 'Status Update',
        dateTime: '2025-03-04 11:30 AM',
        detail: 'Changed project status to "In Progress".',
      },
      {
        id: 4,
        activity: 'Compliance Check Run',
        dateTime: '03/06/2025, 01:10 PM',
        detail: 'Compliance check completed for "Site Plan 2025.pdf". 2 errors found.',
        hasDetails: true,
      },
      {
        id: 5,
        activity: 'Document Updated',
        dateTime: '03/06/2025, 01:10 PM',
        detail: 'Uploaded new version "Site Plan 2025.pdf" (v2.0).',
      },
      {
        id: 6,
        activity: 'Compliance Check Run',
        dateTime: '03/06/2025, 01:10 PM',
        detail: 'Compliance check completed for "Site Plan 2025.pdf" (v2.0). No errors found.',
        hasDetails: true,
      },
      {
        id: 7,
        activity: 'Final Review Completed',
        dateTime: '03/06/2025, 01:10 PM',
        detail: 'Approved site plan after compliance check.',
      },
      {
        id: 8,
        activity: 'Status Update',
        dateTime: '03/06/2025, 01:10 PM',
        detail: 'Changed project status to "Completed".',
      },
    ],
  },
];

const ProjectHistorySection: React.FC<{ project: ProjectHistory }> = ({ project }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Paper sx={{ mb: 3, overflow: 'hidden' }}>
      {/* Project Header */}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'grey.50',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <Typography variant="h6" component="div" sx={{ ml: 2 }}>
          {project.projectName}
        </Typography>
      </Box>

      {/* History Table */}
      <Collapse in={isOpen}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="20%">Activity</TableCell>
                <TableCell width="20%">Date & Time</TableCell>
                <TableCell width="45%">Detail</TableCell>
                <TableCell width="15%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {project.events.map((event, index) => (
                <TableRow
                  key={event.id}
                  sx={{
                    position: 'relative',
                    '&::before': index < project.events.length - 1 ? {
                      content: '""',
                      position: 'absolute',
                      left: '10%',
                      top: '50%',
                      bottom: -8,
                      width: 2,
                      bgcolor: 'grey.300',
                      transform: 'translateX(-50%)',
                    } : {},
                  }}
                >
                  <TableCell>{event.activity}</TableCell>
                  <TableCell>{event.dateTime}</TableCell>
                  <TableCell>{event.detail}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
                      {event.hasDetails && (
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ 
                            bgcolor: 'grey.800',
                            '&:hover': { bgcolor: 'grey.900' },
                          }}
                        >
                          View Details
                        </Button>
                      )}
                      {event.activity.includes('Document') && (
                        <>
                          <IconButton size="small" color="primary">
                            <EditIcon />
                          </IconButton>
                          <IconButton size="small" color="primary">
                            <DownloadIcon />
                          </IconButton>
                          <IconButton size="small" color="error">
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Paper>
  );
};

const History: React.FC = () => {
  return (
    <Box sx={{ pt: '84px', minHeight: '100vh', bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          History
        </Typography>

        {mockData.map((project) => (
          <ProjectHistorySection key={project.projectId} project={project} />
        ))}
      </Container>
    </Box>
  );
};

export default History; 