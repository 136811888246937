import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Alert,
  useTheme,
  alpha,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
  GridView as GridViewIcon,
  List as ListViewIcon,
  Search as SearchIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  MoreVert as MoreVertIcon,
  Add as AddIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import { Project, Permit } from '../types';
import { projectAPI, permitAPI } from '../apis';
import { format } from 'date-fns';

// Constants from ProjectDetails
const PERMIT_TYPES = [
  'Building Permit',
  'Electrical Permit',
  'Plumbing Permit',
  'Mechanical Permit',
  'Demolition Permit',
  'Grading Permit',
  'Other'
];

interface FileItem {
  name: string;
  type: string;
  size: number;
  modifiedAt: Date;
  status?: string;
  version?: string;
  documentID?: string;
  permitID?: string;
}

const Documents: React.FC = () => {
  const theme = useTheme();
  const [projects, setProjects] = useState<Project[]>([]);
  const [permits, setPermits] = useState<Permit[]>([]);
  const [selectedProject, setSelectedProject] = useState<string>('');
  const [selectedPermit, setSelectedPermit] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [files, setFiles] = useState<FileItem[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileItem | null>(null);
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [isCreatePermitDialogOpen, setIsCreatePermitDialogOpen] = useState(false);
  const [permitData, setPermitData] = useState({
    permitType: '',
    estimatedTimeline: '',
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png']
    },
    onDrop: async (acceptedFiles) => {
      if (!selectedProject || !selectedPermit) {
        setError('Please select a project and permit first');
        return;
      }

      setLoading(true);
      setError(null);
      setSuccess(null);

      try {
        const formData = new FormData();
        acceptedFiles.forEach((file) => {
          formData.append('files', file);
        });

        const response = await permitAPI.createPermit(
          {
            permitID: selectedPermit,
            projectID: selectedProject,
          },
          acceptedFiles
        );

        if (response.status === 'success') {
          setSuccess('Documents uploaded successfully');
          // Add new files to the list
          const newFiles = acceptedFiles.map(file => ({
            name: file.name,
            type: file.type,
            size: file.size,
            modifiedAt: new Date(),
            status: 'pending',
            version: '1.0',
            documentID: Math.random().toString(36).substr(2, 9),
            permitID: selectedPermit,
          }));
          setFiles(prev => [...prev, ...newFiles]);
        } else {
          throw new Error(response.message || 'Failed to upload documents');
        }
      } catch (error) {
        console.error('Error uploading documents:', error);
        setError('Failed to upload documents. Please try again.');
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await projectAPI.getProjects();
        if (response.status === 'success' && response.data) {
          setProjects(response.data);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    const fetchPermits = async () => {
      if (!selectedProject) {
        setPermits([]);
        return;
      }

      try {
        const response = await permitAPI.getPermits();
        if (response.status === 'success' && response.data) {
          const projectPermits = response.data.filter(
            permit => permit.projectID === selectedProject
          );
          setPermits(projectPermits);
        }
      } catch (error) {
        console.error('Error fetching permits:', error);
      }
    };

    fetchPermits();
  }, [selectedProject]);

  // Helper function to get existing permit for a permit type
  const getExistingPermit = (permitType: string) => {
    return permits.find(permit => permit.permitType === permitType);
  };

  const getFileIcon = (fileType: string) => {
    if (fileType.includes('pdf')) return <PdfIcon color="error" />;
    if (fileType.includes('image')) return <ImageIcon color="primary" />;
    return <FileIcon color="action" />;
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const getStatusChip = (status: string) => {
    let color: 'success' | 'error' | 'warning' | 'default' = 'default';
    let icon: React.ReactNode = null;

    switch (status.toLowerCase()) {
      case 'approved':
        color = 'success';
        icon = <CheckCircleIcon />;
        break;
      case 'rejected':
        color = 'error';
        icon = <CancelIcon />;
        break;
      case 'pending':
        color = 'warning';
        icon = <WarningIcon />;
        break;
    }

    return (
      <Chip
        icon={icon || undefined}
        label={status.charAt(0).toUpperCase() + status.slice(1)}
        color={color}
        size="small"
      />
    );
  };

  const handleFileClick = (file: FileItem) => {
    setSelectedFile(file);
    setIsFileDialogOpen(true);
  };

  const handleCreatePermit = () => {
    setIsCreatePermitDialogOpen(true);
  };

  const handlePermitSubmit = async () => {
    if (!selectedProject) return;

    try {
      setError(null);
      const response = await permitAPI.createPermit({
        ...permitData,
        projectID: selectedProject,
      }, []);

      if (response.status === 'success' && response.data) {
        setPermits(prev => [...prev, response.data]);
        setIsCreatePermitDialogOpen(false);
        setPermitData({ permitType: '', estimatedTimeline: '' });
      } else {
        throw new Error(response.message || 'Failed to create permit');
      }
    } catch (error) {
      console.error('Error creating permit:', error);
      setError('Failed to create permit. Please try again later.');
    }
  };

  return (
    <Box sx={{ pt: '84px', minHeight: '100vh', bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        {/* Header Section */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h4">Documents</Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(_, newMode) => newMode && setViewMode(newMode)}
              size="small"
            >
              <ToggleButton value="grid">
                <Tooltip title="Grid view">
                  <GridViewIcon />
                </Tooltip>
              </ToggleButton>
              <ToggleButton value="list">
                <Tooltip title="List view">
                  <ListViewIcon />
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreatePermit}
            >
              New Permit
            </Button>
          </Box>
        </Box>

        {/* Project and Permit Selection */}
        <Paper sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Select Project</InputLabel>
                <Select
                  value={selectedProject}
                  label="Select Project"
                  onChange={(e) => {
                    setSelectedProject(e.target.value);
                    setSelectedPermit('');
                  }}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.projectID} value={project.projectID}>
                      <Box>
                        <Typography variant="subtitle1">{project.name}</Typography>
                        <Typography variant="caption" color="text.secondary">
                          {project.projectType} • {project.address}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Select Permit</InputLabel>
                <Select
                  value={selectedPermit}
                  label="Select Permit"
                  onChange={(e) => setSelectedPermit(e.target.value)}
                  disabled={!selectedProject}
                >
                  {PERMIT_TYPES.map((type) => {
                    const existingPermit = getExistingPermit(type);
                    return (
                      <MenuItem 
                        key={existingPermit?.permitID || type} 
                        value={existingPermit?.permitID || type}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">
                            {type}
                          </Typography>
                          {existingPermit ? (
                            <Typography variant="caption" color="text.secondary">
                              Status: {existingPermit.status} • Created: {format(new Date(existingPermit.submissionDate), 'MMM d, yyyy')}
                            </Typography>
                          ) : (
                            <Typography variant="caption" color="text.secondary">
                              No permit created yet
                            </Typography>
                          )}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {/* Alerts */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {success}
          </Alert>
        )}

        {/* File Upload Area */}
        <Paper sx={{ mb: 3 }}>
          <Box
            {...getRootProps()}
            sx={{
              border: '2px dashed',
              borderColor: 'divider',
              borderRadius: 1,
              p: 3,
              textAlign: 'center',
              cursor: 'pointer',
              transition: 'all 0.2s ease-in-out',
              '&:hover': {
                borderColor: 'primary.main',
                bgcolor: alpha(theme.palette.primary.main, 0.04),
                transform: 'scale(1.01)',
              },
            }}
          >
            <input {...getInputProps()} />
            {loading ? (
              <CircularProgress size={40} />
            ) : (
              <>
                <CloudUploadIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 1 }} />
                <Typography variant="h6" gutterBottom>
                  Drag and drop files here, or click to select files
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Supported formats: PDF, DOC, DOCX, JPG, PNG
                </Typography>
              </>
            )}
          </Box>
        </Paper>

        {/* File List */}
        <Paper sx={{ p: 2 }}>
          {viewMode === 'list' ? (
            <List>
              {files.map((file, index) => (
                <React.Fragment key={file.name + index}>
                  <ListItem
                    sx={{
                      borderRadius: 1,
                      transition: 'all 0.2s ease-in-out',
                      cursor: 'pointer',
                      '&:hover': {
                        bgcolor: alpha(theme.palette.primary.main, 0.04),
                        transform: 'translateX(4px)',
                      },
                    }}
                  >
                    <ListItemIcon>
                      {getFileIcon(file.type)}
                    </ListItemIcon>
                    <ListItemText
                      primary={file.name}
                      secondary={
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                          <Typography variant="caption">
                            {formatFileSize(file.size)}
                          </Typography>
                          <Typography variant="caption">
                            Modified {format(file.modifiedAt, 'MMM d, yyyy')}
                          </Typography>
                          {file.version && (
                            <Typography variant="caption">
                              Version {file.version}
                            </Typography>
                          )}
                        </Box>
                      }
                      onClick={() => handleFileClick(file)}
                    />
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      {file.status && getStatusChip(file.status)}
                      <IconButton edge="end" size="small">
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </ListItem>
                  {index < files.length - 1 && <Divider />}
                </React.Fragment>
              ))}
              {files.length === 0 && (
                <ListItem>
                  <ListItemText
                    primary="No files uploaded yet"
                    secondary="Upload files by dragging them into the upload area above"
                  />
                </ListItem>
              )}
            </List>
          ) : (
            <Grid container spacing={2}>
              {files.map((file, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={file.name + index}>
                  <Card
                    sx={{
                      height: '100%',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 4,
                      },
                      cursor: 'pointer',
                    }}
                    onClick={() => handleFileClick(file)}
                  >
                    <CardContent sx={{ p: 2 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                        {getFileIcon(file.type)}
                        <Typography noWrap sx={{ width: '100%', textAlign: 'center', fontWeight: 500 }}>
                          {file.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {formatFileSize(file.size)}
                        </Typography>
                        {file.status && (
                          <Box sx={{ mt: 1 }}>
                            {getStatusChip(file.status)}
                          </Box>
                        )}
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              {files.length === 0 && (
                <Grid item xs={12}>
                  <Box sx={{ textAlign: 'center', py: 4 }}>
                    <DescriptionIcon sx={{ fontSize: 48, color: 'text.secondary', mb: 2 }} />
                    <Typography color="text.secondary">
                      No files uploaded yet
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Paper>

        {/* File Details Dialog */}
        <Dialog
          open={isFileDialogOpen}
          onClose={() => setIsFileDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>File Details</DialogTitle>
          <DialogContent>
            {selectedFile && (
              <Grid container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
                  {getFileIcon(selectedFile.type)}
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="text.secondary">
                    File Name
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedFile.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Size
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {formatFileSize(selectedFile.size)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Modified
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    {format(selectedFile.modifiedAt, 'MMM d, yyyy')}
                  </Typography>
                </Grid>
                {selectedFile.version && (
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Version
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {selectedFile.version}
                    </Typography>
                  </Grid>
                )}
                {selectedFile.status && (
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" color="text.secondary">
                      Status
                    </Typography>
                    <Box sx={{ mt: 0.5 }}>
                      {getStatusChip(selectedFile.status)}
                    </Box>
                  </Grid>
                )}
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<DownloadIcon />}
              onClick={() => {/* Implement download logic */}}
            >
              Download
            </Button>
            <Button onClick={() => setIsFileDialogOpen(false)}>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Create Permit Dialog */}
        <Dialog
          open={isCreatePermitDialogOpen}
          onClose={() => setIsCreatePermitDialogOpen(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Create New Permit</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ mb: 2 }}>
              Please select the permit type and estimated timeline for completion.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Permit Type</InputLabel>
                  <Select
                    value={permitData.permitType}
                    label="Permit Type"
                    onChange={(e) => setPermitData({ ...permitData, permitType: e.target.value })}
                  >
                    {PERMIT_TYPES.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Estimated Timeline (weeks)"
                  type="number"
                  value={permitData.estimatedTimeline}
                  onChange={(e) => setPermitData({ ...permitData, estimatedTimeline: e.target.value })}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsCreatePermitDialogOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handlePermitSubmit}
              disabled={!permitData.permitType || !permitData.estimatedTimeline}
            >
              Create Permit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
};

export default Documents; 