import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  InputBase, 
  IconButton, 
  Badge, 
  Avatar,
  styled,
  Paper,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const SearchWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '8px',
  backgroundColor: '#F7F7F7',
  marginRight: '16px',
  width: '300px',
  display: 'flex',
  alignItems: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: '8px 8px 8px 0',
    paddingLeft: '40px',
    width: '100%',
  },
}));

const NotificationDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '380px',
    boxSizing: 'border-box',
    borderLeft: '1px solid #eee',
    padding: '0',
  },
});

interface Notification {
  id: number;
  title: string;
  description: string;
  time: string;
  isUnread: boolean;
}

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const notifications: Notification[] = [
    {
      id: 1,
      title: 'Project Approved',
      description: 'Downtown Highrise project has been approved by the review board.',
      time: '2 minutes ago',
      isUnread: true,
    },
    {
      id: 2,
      title: 'New Comment',
      description: 'John left a comment on Riverside Apartment project documents.',
      time: '1 hour ago',
      isUnread: true,
    },
    {
      id: 3,
      title: 'Document Update',
      description: 'Tech Hub Office project documents have been updated.',
      time: '3 hours ago',
      isUnread: false,
    },
    {
      id: 4,
      title: 'Review Request',
      description: 'Green Valley Homes project needs your review.',
      time: 'Yesterday',
      isUnread: false,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleNotifications = () => {
    setNotificationsOpen(!notificationsOpen);
  };

  return (
    <Paper
      elevation={isScrolled ? 2 : 0}
      sx={{ 
        position: 'fixed',
        top: 0,
        left: '240px',
        right: 0,
        zIndex: 1100,
        backgroundColor: 'background.default',
        transition: 'box-shadow 0.3s ease-in-out',
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        px: 4,
        py: 2,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" sx={{ mr: 1 }}>Page</Typography>
          <Typography variant="h6" color="text.secondary">/</Typography>
          <Typography variant="h6" sx={{ ml: 1, color: '#1E4C9A' }}>Overview</Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <SearchWrapper>
            <IconButton sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <StyledInputBase
              placeholder="Type here..."
              inputProps={{ 'aria-label': 'search' }}
            />
          </SearchWrapper>

          <IconButton 
            size="large" 
            sx={{ mr: 2 }}
            onClick={toggleNotifications}
          >
            <Badge badgeContent={2} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <Avatar sx={{ bgcolor: '#2C3E50' }}>M</Avatar>
        </Box>
      </Box>

      <NotificationDrawer
        anchor="right"
        open={notificationsOpen}
        onClose={toggleNotifications}
      >
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px solid #eee' }}>
          <Typography variant="h6">Notifications</Typography>
          <IconButton onClick={toggleNotifications} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        
        <List sx={{ p: 0 }}>
          {notifications.map((notification) => (
            <React.Fragment key={notification.id}>
              <ListItem 
                sx={{ 
                  py: 2,
                  px: 2,
                  backgroundColor: notification.isUnread ? 'rgba(108, 92, 231, 0.04)' : 'transparent',
                  transition: 'background-color 0.2s ease',
                  '&:hover': {
                    backgroundColor: 'rgba(108, 92, 231, 0.08)',
                  },
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                    {notification.isUnread && (
                      <FiberManualRecordIcon 
                        sx={{ 
                          fontSize: 8, 
                          color: '#1E4C9A',
                          mr: 1
                        }} 
                      />
                    )}
                    <Typography variant="subtitle2" sx={{ fontWeight: notification.isUnread ? 600 : 400 }}>
                      {notification.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    {notification.description}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {notification.time}
                  </Typography>
                </Box>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </NotificationDrawer>
    </Paper>
  );
};

export default Header; 