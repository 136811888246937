import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  IconButton,
  Chip,
  styled,
  keyframes,
  Grid,
  Card,
  CardContent,
  Divider,
  alpha,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import FunctionsIcon from '@mui/icons-material/Functions';
import { chatAPI, projectAPI, permitAPI } from '../apis';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
`;

const TypingBubble = styled(Box)({
  display: 'flex',
  gap: '4px',
  padding: '12px 16px',
  background: 'linear-gradient(135deg, #1E4C9A 0%, #3671D8 100%)',
  borderRadius: '16px',
  alignSelf: 'flex-start',
  boxShadow: '0 4px 15px rgba(30, 76, 154, 0.2)',
  animation: `${fadeIn} 0.3s ease-out`,
  '& .dot': {
    width: '8px',
    height: '8px',
    backgroundColor: '#fff',
    borderRadius: '50%',
    animation: `${bounce} 1s infinite`,
    opacity: 0.9,
    '&:nth-of-type(2)': {
      animationDelay: '0.2s',
    },
    '&:nth-of-type(3)': {
      animationDelay: '0.4s',
    },
  },
});

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 220px)',
  marginTop: '80px',
  background: 'linear-gradient(135deg, #f6f8fd 0%, #f0f2f8 100%)',
  borderRadius: '24px',
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(255, 255, 255, 0.4)',
});

const MessagesContainer = styled(Box)({
  flex: 1,
  overflowY: 'auto',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#c0c5d1',
    borderRadius: '4px',
    '&:hover': {
      background: '#a8aebb',
    },
  },
});

const InputContainer = styled(Paper)({
  padding: '20px',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
});

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface APIFunction {
  name: string;
  description: string;
  parameters: {
    name: string;
    type: string;
    required: boolean;
    description: string;
    options?: string[];
  }[];
  api: (...args: any[]) => Promise<any>;
}

const API_FUNCTIONS: { [key: string]: APIFunction } = {
  'Get Projects': {
    name: 'getProjects',
    description: 'Get all projects',
    parameters: [],
    api: projectAPI.getProjects
  },
  'Get Project': {
    name: 'getProject',
    description: 'Get a specific project by ID',
    parameters: [
      {
        name: 'projectId',
        type: 'string',
        required: true,
        description: 'The ID of the project to retrieve'
      }
    ],
    api: projectAPI.getProject
  },
  'Create Project': {
    name: 'createProject',
    description: 'Create a new project',
    parameters: [
      {
        name: 'name',
        type: 'string',
        required: true,
        description: 'Project name'
      },
      {
        name: 'description',
        type: 'string',
        required: true,
        description: 'Project description'
      },
      {
        name: 'projectType',
        type: 'string',
        required: true,
        description: 'Type of project',
        options: ['Commercial', 'Residential', 'Industrial', 'Infrastructure', 'Mixed-Use', 'Other']
      },
      {
        name: 'jurisdictionID',
        type: 'string',
        required: true,
        description: 'ID of the jurisdiction'
      },
      {
        name: 'estimatedCost',
        type: 'number',
        required: true,
        description: 'Estimated cost of the project'
      }
    ],
    api: projectAPI.createProject
  },
  'Get Permits': {
    name: 'getPermits',
    description: 'Get all permits',
    parameters: [],
    api: permitAPI.getPermits
  },
  'Create Permit': {
    name: 'createPermit',
    description: 'Create a new permit',
    parameters: [
      {
        name: 'permitType',
        type: 'string',
        required: true,
        description: 'Type of permit',
        options: ['Building Permit', 'Electrical Permit', 'Plumbing Permit', 'Mechanical Permit', 'Demolition Permit', 'Grading Permit', 'Other']
      },
      {
        name: 'projectID',
        type: 'string',
        required: true,
        description: 'ID of the project'
      },
      {
        name: 'estimatedTimeline',
        type: 'string',
        required: true,
        description: 'Estimated timeline for the permit'
      },
      {
        name: 'files',
        type: 'files',
        required: true,
        description: 'Documents to attach to the permit'
      }
    ],
    api: permitAPI.createPermit
  },
  'Run Compliance Check': {
    name: 'runComplianceCheck',
    description: 'Run a compliance check for a project',
    parameters: [
      {
        name: 'projectId',
        type: 'string',
        required: true,
        description: 'The ID of the project to check'
      }
    ],
    api: projectAPI.runComplianceCheck
  }
};

const MessageBubble = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isUser'
})<{ isUser: boolean }>(({ isUser, theme }) => ({
  padding: '16px',
  borderRadius: '20px',
  maxWidth: '80%',
  animation: `${fadeIn} 0.3s ease-out`,
  transition: 'all 0.2s ease',
  position: 'relative',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
  ...(isUser ? {
    background: 'linear-gradient(135deg, #1E4C9A 0%, #3671D8 100%)',
    color: 'white',
    alignSelf: 'flex-end',
    borderBottomRightRadius: '4px',
    boxShadow: '0 4px 15px rgba(30, 76, 154, 0.2)',
  } : {
    background: 'white',
    alignSelf: 'flex-start',
    borderBottomLeftRadius: '4px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
  }),
}));

const StyledCard = styled(Card)({
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '24px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  border: '1px solid rgba(255, 255, 255, 0.4)',
  overflow: 'visible',
});

const FunctionCard = styled(Box)({
  padding: '16px',
  borderRadius: '16px',
  background: 'white',
  transition: 'all 0.2s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 20px rgba(30, 76, 154, 0.15)',
  },
});

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '8px',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: '0 2px 8px rgba(30, 76, 154, 0.15)',
  },
}));

const Saru: React.FC = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([
    {
      role: 'assistant',
      content: "Hi! I'm Saru, your AI assistant. I can help you with:\n\n" +
        Object.entries(API_FUNCTIONS)
          .map(([name, func]) => `• ${name}: ${func.description}`)
          .join('\n')
    }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFunction, setSelectedFunction] = useState<APIFunction | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleFunctionClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFunctionClose = () => {
    setAnchorEl(null);
  };

  const handleFunctionSelect = async (functionName: string) => {
    handleFunctionClose();
    const func = API_FUNCTIONS[functionName];
    if (!func) {
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: `I'm sorry, I don't know how to handle the function "${functionName}".`
      }]);
      return;
    }

    setSelectedFunction(func);
    
    if (func.parameters.length === 0) {
      try {
        const result = await func.api();
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: `Here's the result of ${func.name}:\n\n${JSON.stringify(result, null, 2)}`
        }]);
      } catch (error) {
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: `I encountered an error while executing ${func.name}: ${error instanceof Error ? error.message : 'Unknown error'}`
        }]);
      }
    } else {
      const paramPrompt = `I'll help you with ${func.name}. I need the following information:\n\n` +
        func.parameters.map(p => `- ${p.name}${p.required ? ' (required)' : ''}: ${p.description}`).join('\n');
      
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: paramPrompt
      }]);
    }
  };

  const parseFunctionParams = (message: string, func: APIFunction) => {
    const values: {[key: string]: any} = {};
    const missing: typeof func.parameters = [];

    func.parameters.forEach(param => {
      const regex = new RegExp(`${param.name}[:\\s]+(.*?)(?=\\s+\\w+[:\\s]|$)`, 'i');
      const match = message.match(regex);

      if (match) {
        const value = match[1].trim();
        if (param.type === 'number') {
          values[param.name] = parseFloat(value);
        } else {
          values[param.name] = value;
        }
      } else if (param.required) {
        missing.push(param);
      }
    });

    return { values, missing };
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      const userMessage = { role: 'user' as const, content: message.trim() };
      setMessages(prev => [...prev, userMessage]);
      setMessage('');
      setIsLoading(true);

      try {
        if (selectedFunction) {
          const params = parseFunctionParams(message, selectedFunction);
          if (params.missing.length > 0) {
            setMessages(prev => [...prev, {
              role: 'assistant',
              content: `I still need the following parameters:\n\n${params.missing.map(p => `- ${p.name}: ${p.description}`).join('\n')}`
            }]);
          } else {
            try {
              const result = await selectedFunction.api(params.values);
              setMessages(prev => [...prev, {
                role: 'assistant',
                content: `Here's the result of ${selectedFunction.name}:\n\n${JSON.stringify(result, null, 2)}`
              }]);
              setSelectedFunction(null);
            } catch (error) {
              setMessages(prev => [...prev, {
                role: 'assistant',
                content: `I encountered an error while executing ${selectedFunction.name}: ${error instanceof Error ? error.message : 'Unknown error'}`
              }]);
            }
          }
        } else {
          const functionMatch = Object.entries(API_FUNCTIONS).find(([name]) => 
            message.toLowerCase().includes(name.toLowerCase())
          );

          if (functionMatch) {
            await handleFunctionSelect(functionMatch[0]);
          } else {
            const response = await chatAPI.sendMessage(userMessage.content);
            
            if (response.status === 'success' && response.message?.content) {
              setMessages(prev => [...prev, {
                role: 'assistant',
                content: response.message.content
              }]);
            } else {
              throw new Error(response.error || 'Invalid response format');
            }
          }
        }
      } catch (error) {
        console.error('Error sending message:', error);
        setMessages(prev => [...prev, {
          role: 'assistant',
          content: "I'm sorry, I encountered an error. Please try again later."
        }]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ChatContainer>
            <MessagesContainer>
              {messages.map((msg, index) => (
                <MessageBubble
                  key={index}
                  isUser={msg.role === 'user'}
                >
                  <Typography sx={{ 
                    whiteSpace: 'pre-wrap',
                    fontSize: '1rem',
                    lineHeight: 1.6,
                  }}>
                    {msg.content}
                  </Typography>
                </MessageBubble>
              ))}
              {isLoading && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', my: 2 }}>
                  <TypingBubble>
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </TypingBubble>
                </Box>
              )}
              <div ref={messagesEndRef} />
            </MessagesContainer>
            <InputContainer elevation={0}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <IconButton
                  onClick={handleFunctionClick}
                  sx={{
                    background: 'linear-gradient(135deg, #1E4C9A 0%, #3671D8 100%)',
                    color: 'white',
                    width: 48,
                    height: 48,
                    borderRadius: '14px',
                    '&:hover': {
                      background: 'linear-gradient(135deg, #5a4bd9 0%, #9177f5 100%)',
                    },
                  }}
                >
                  <FunctionsIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleFunctionClose}
                  PaperProps={{
                    sx: {
                      mt: 1,
                      borderRadius: '16px',
                      boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
                      border: '1px solid rgba(255, 255, 255, 0.4)',
                      background: 'rgba(255, 255, 255, 0.9)',
                      backdropFilter: 'blur(10px)',
                      '& .MuiMenuItem-root': {
                        px: 2,
                        py: 1.5,
                        borderRadius: '8px',
                        mx: 1,
                        my: 0.5,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          background: 'rgba(108, 92, 231, 0.1)',
                        },
                      },
                    },
                  }}
                >
                  {Object.entries(API_FUNCTIONS).map(([name, func]) => (
                    <MenuItem key={name} onClick={() => handleFunctionSelect(name)}>
                      <Box>
                        <Typography variant="subtitle1" fontWeight={500}>
                          {name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {func.description}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Menu>
                <TextField
                  fullWidth
                  multiline
                  maxRows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Type your message..."
                  variant="outlined"
                  size="medium"
                  disabled={isLoading}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      backdropFilter: 'blur(10px)',
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                      },
                      '&.Mui-focused': {
                        backgroundColor: 'white',
                        boxShadow: '0 4px 12px rgba(108, 92, 231, 0.1)',
                      },
                    },
                  }}
                />
                <IconButton
                  onClick={handleSendMessage}
                  disabled={!message.trim() || isLoading}
                  sx={{
                    background: 'linear-gradient(135deg, #1E4C9A 0%, #3671D8 100%)',
                    color: 'white',
                    width: 48,
                    height: 48,
                    borderRadius: '14px',
                    '&:hover': {
                      background: 'linear-gradient(135deg, #5a4bd9 0%, #9177f5 100%)',
                    },
                    '&.Mui-disabled': {
                      background: '#e2e8f0',
                      color: '#a0aec0',
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </InputContainer>
          </ChatContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Saru; 