import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Avatar,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  Business as BusinessIcon,
  LocationOn as LocationIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

const mockUser = {
  id: 1,
  name: 'John Doe',
  email: 'john.doe@example.com',
  phone: '+1 (555) 123-4567',
  company: 'ABC Construction Co.',
  role: 'Project Manager',
  location: 'San Francisco, CA',
  avatar: 'JD',
  recentActivity: [
    {
      id: 1,
      action: 'Created new project',
      project: 'Commercial Building A',
      date: '2024-03-15',
    },
    {
      id: 2,
      action: 'Uploaded documents',
      project: 'Residential Complex B',
      date: '2024-03-14',
    },
    {
      id: 3,
      action: 'Submitted permit application',
      project: 'Office Renovation C',
      date: '2024-03-13',
    },
  ],
};

const Profile: React.FC = () => {
  return (
    <Box sx={{ pt: '84px', minHeight: '100vh', bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {/* Profile Overview */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, textAlign: 'center' }}>
              <Avatar
                sx={{
                  width: 120,
                  height: 120,
                  margin: '0 auto 16px',
                  bgcolor: 'primary.main',
                  fontSize: '2.5rem',
                }}
              >
                {mockUser.avatar}
              </Avatar>
              <Typography variant="h5" gutterBottom>
                {mockUser.name}
              </Typography>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                {mockUser.role}
              </Typography>
              <Button
                variant="outlined"
                startIcon={<EditIcon />}
                sx={{ mt: 2 }}
              >
                Edit Profile
              </Button>
            </Paper>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Contact Information
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Email"
                    secondary={mockUser.email}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Phone"
                    secondary={mockUser.phone}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <BusinessIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Company"
                    secondary={mockUser.company}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <LocationIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Location"
                    secondary={mockUser.location}
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>

          {/* Recent Activity */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Recent Activity
              </Typography>
              <List>
                {mockUser.recentActivity.map((activity, index) => (
                  <React.Fragment key={activity.id}>
                    <ListItem>
                      <ListItemText
                        primary={activity.action}
                        secondary={`${activity.project} • ${new Date(activity.date).toLocaleDateString()}`}
                      />
                    </ListItem>
                    {index < mockUser.recentActivity.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Profile; 