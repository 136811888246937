import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  MenuItem,
  useTheme,
  useMediaQuery,
  Stepper,
  Step,
  StepLabel,
  alpha,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  IconButton,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  Permit,
  Document,
  BuildingCode,
  ComplianceCheck,
  Inspection,
  UploadedFile,
  DocumentPreviewProps,
  AIAnalysis,
  DocumentAnalysis
} from '../types';
import { permitAPI, documentAPI } from '../apis';

interface NewPermitForm {
  title: string;
  description: string;
  type: string;
  location: string;
  estimatedCost: string;
  projectStartDate: string;
}

const permitTypes = [
  'Building Permit',
  'Electrical Permit',
  'Plumbing Permit',
  'Mechanical Permit',
  'Demolition Permit',
  'Renovation Permit',
];

const steps = ['Basic Information', 'Document Upload', 'AI Analysis'];

const API_BASE_URL = 'https://augence.onrender.com';

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ file, documentAnalysis }) => {
  const theme = useTheme();
  const [previewUrl, setPreviewUrl] = useState<string>('');

  useEffect(() => {
    if (!file) return;
    
    const url = URL.createObjectURL(file);
    setPreviewUrl(url);
    
    return () => URL.revokeObjectURL(url);
  }, [file]);

  return (
    <Box>
      <Box sx={{ 
        position: 'relative',
        mb: 2,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden'
      }}>
        {file.type.startsWith('image/') ? (
          <img 
            src={previewUrl} 
            alt={file.name}
            style={{ 
              width: '100%',
              height: 'auto',
              maxHeight: '300px',
              objectFit: 'contain'
            }}
          />
        ) : (
          <Box sx={{ 
            p: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            bgcolor: alpha(theme.palette.primary.main, 0.05)
          }}>
            <InsertDriveFileIcon sx={{ fontSize: 40, color: 'primary.main' }} />
            <Box>
              <Typography variant="subtitle1">{file.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {(file.size / 1024 / 1024).toFixed(2)} MB
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {documentAnalysis && (
        <Box sx={{ mt: 2 }}>
          <Paper sx={{ 
            p: 2,
            bgcolor: 'background.default',
            borderLeft: 6,
            borderColor: documentAnalysis.status === 'complete' ? 'success.main' :
                        documentAnalysis.status === 'incomplete' ? 'warning.main' : 'error.main'
          }}>
            <Typography variant="subtitle1" color="primary">
              Document Status: {documentAnalysis.status}
            </Typography>
            {documentAnalysis.requiredChanges && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Required Changes: {documentAnalysis.requiredChanges}
              </Typography>
            )}
            {documentAnalysis.officialNotes && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Official Notes: {documentAnalysis.officialNotes}
              </Typography>
            )}
          </Paper>
        </Box>
      )}
    </Box>
  );
};

interface ExtendedUploadedFile extends UploadedFile {
  status: string;
  uploadDate: string;
}

const NewPermit: React.FC = () => {
  const theme = useTheme();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);
  const [files, setFiles] = useState<ExtendedUploadedFile[]>([]);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [complianceChecks, setComplianceChecks] = useState<ComplianceCheck[]>([]);
  const [buildingCodes, setBuildingCodes] = useState<BuildingCode[]>([]);
  const [inspections, setInspections] = useState<Inspection[]>([]);
  const [form, setForm] = useState<NewPermitForm>({
    title: '',
    description: '',
    type: '',
    location: '',
    estimatedCost: '',
    projectStartDate: '',
  });
  const reportRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const newFiles: ExtendedUploadedFile[] = acceptedFiles.map(file => {
      const uploadedFile = file as ExtendedUploadedFile;
      uploadedFile.preview = URL.createObjectURL(file);
      uploadedFile.documentId = undefined;
      uploadedFile.status = 'pending';
      uploadedFile.uploadDate = new Date().toISOString();
      return uploadedFile;
    });

    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.png', '.jpg', '.jpeg'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    }
  });

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleNext = () => {
    if (activeStep === steps.length - 2) {
      handleSubmit();
    } else {
      setActiveStep(prev => prev + 1);
    }
  };

  const handleSubmit = async () => {
    setIsAnalyzing(true);
    setError(null);

    try {
      // First create the permit
      const permitData: Partial<Permit> = {
        projectID: projectId,
        permitType: form.type,
        status: 'pending',
        submissionDate: new Date().toISOString(),
        currentReviewStage: 'initial_review',
        estimatedTimeline: '2-4 weeks',
        assignedReviewerID: undefined
      };

      const permitResponse = await permitAPI.createPermit(permitData, files);
      
      if (permitResponse.status !== 'success' || !permitResponse.data) {
        throw new Error(permitResponse.message || 'Failed to create permit');
      }

      const newPermit = permitResponse.data;

      // Then analyze the permit documents
      const analysisResponse = await permitAPI.analyzePermit(newPermit.permitID, files);
      
      if (analysisResponse.status === 'success' && analysisResponse.data) {
        const analysis = analysisResponse.data;
        setBuildingCodes(analysis.regulationMatches.map(match => ({
          codeID: match.code,
          jurisdictionID: projectId,
          category: 'Building',
          codeNumber: match.code,
          description: match.title,
          effectiveDate: new Date()
        })));
        
        setComplianceChecks(analysis.regulationMatches.map(match => ({
          checkID: `check-${match.code}`,
          permitID: newPermit.permitID,
          documentID: '',
          codeID: match.code,
          status: match.status === 'compliant' ? 'passed' : 'failed',
          issues: match.details,
          checkedDate: new Date(),
          checkedByAI: true
        })));
        
        setInspections(analysis.documentAnalysis.map((doc, index) => ({
          inspectionID: `inspection-${index}`,
          permitID: newPermit.permitID,
          type: doc.documentType,
          scheduledDate: new Date(),
          status: doc.status,
          inspectorID: undefined
        })));
        
        setActiveStep(prev => prev + 1);
      } else {
        throw new Error(analysisResponse.message || 'Failed to analyze permit');
      }
    } catch (error) {
      console.error('Error submitting permit:', error);
      setError('Failed to submit permit. Please try again later.');
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleBack = () => {
    setActiveStep(prev => prev - 1);
  };

  const handleFileDelete = async (fileToDelete: ExtendedUploadedFile) => {
    try {
      // If the file has been uploaded and has a document ID, delete it from the server
      if (fileToDelete.documentId) {
        await documentAPI.deleteDocument(fileToDelete.documentId);
      }
      
      // Remove the file from local state
      setFiles(prevFiles => prevFiles.filter(file => file !== fileToDelete));
    } catch (error) {
      console.error('Error deleting file:', error);
      setError('Failed to delete file. Please try again.');
    }
  };

  const handleDownload = async () => {
    if (!reportRef.current) return;

    try {
      const canvas = await html2canvas(reportRef.current);
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const pdf = new jsPDF('p', 'mm', 'a4');
      
      let heightLeft = imgHeight;
      let position = 0;
      let pageData = canvas.toDataURL('image/jpeg', 1.0);

      pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save(`permit-analysis-${projectId}-${new Date().toISOString().split('T')[0]}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      setError('Failed to generate PDF report. Please try again.');
    }
  };

  const renderAnalysisStatus = () => {
    if (isAnalyzing) {
      return (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Analyzing Permit Documents
              </Typography>
          <Typography color="text.secondary">
            This may take a few minutes...
                  </Typography>
              </Box>
      );
    }

    const totalChecks = complianceChecks.length;
    const passedChecks = complianceChecks.filter(check => check.status === 'passed').length;
    const complianceScore = totalChecks > 0 ? (passedChecks / totalChecks) * 100 : 0;

    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mb: 3, bgcolor: alpha(theme.palette.primary.main, 0.03) }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                <AccountBalanceIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                <Box>
                  <Typography variant="h6">Permit Analysis Results</Typography>
                  <Typography color="text.secondary">
                    Compliance Assessment Report
                  </Typography>
                </Box>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
                  {Math.round(complianceScore)}%
                </Typography>
                <Typography variant="body1">
                  Compliance Score
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Building Codes</Typography>
                <Grid container spacing={2}>
                  {buildingCodes.map((code, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1" color="primary">
                          {code.codeNumber}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {code.description}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Category: {code.category}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Compliance Checks</Typography>
                <Grid container spacing={2}>
                  {complianceChecks.map((check, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Paper sx={{ 
                        p: 2, 
                        borderLeft: 6,
                        borderColor: check.status === 'passed' ? 'success.main' : 'warning.main'
                      }}>
                        <Typography variant="subtitle1">{check.codeID}</Typography>
                        <Typography variant="body2" color={
                          check.status === 'passed' ? 'success.main' : 'warning.main'
                        }>
                          Status: {check.status}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Issues: {check.issues}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>Required Inspections</Typography>
                <Grid container spacing={2}>
                  {inspections.map((inspection, index) => (
                    <Grid item xs={12} key={index}>
                      <Paper sx={{ p: 2 }}>
                        <Typography variant="subtitle1">{inspection.type}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          Status: {inspection.status}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          Scheduled: {inspection.scheduledDate.toLocaleDateString()}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Alert 
              severity={
                complianceScore >= 80 ? 'success' :
                complianceScore >= 50 ? 'warning' : 'error'
              }
              sx={{ mt: 2 }}
            >
              {complianceScore >= 80 ? (
                'This permit application has a high compliance score. Please review all requirements before final submission.'
              ) : complianceScore >= 50 ? (
                'This permit application requires attention to several compliance issues. Address all recommendations to improve compliance.'
              ) : (
                'This permit application has significant compliance issues. Please carefully review all requirements and resubmit with necessary corrections.'
              )}
            </Alert>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Permit Title"
                name="title"
                value={form.title}
                onChange={handleFormChange}
                error={!form.title && activeStep > 0}
                helperText={!form.title && activeStep > 0 ? 'Title is required' : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Description"
                name="description"
                multiline
                rows={4}
                value={form.description}
                onChange={handleFormChange}
                error={!form.description && activeStep > 0}
                helperText={!form.description && activeStep > 0 ? 'Description is required' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                select
                label="Permit Type"
                name="type"
                value={form.type}
                onChange={handleFormChange}
                error={!form.type && activeStep > 0}
                helperText={!form.type && activeStep > 0 ? 'Permit type is required' : ''}
              >
                {permitTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Location"
                name="location"
                value={form.location}
                onChange={handleFormChange}
                error={!form.location && activeStep > 0}
                helperText={!form.location && activeStep > 0 ? 'Location is required' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Estimated Cost ($)"
                name="estimatedCost"
                type="number"
                value={form.estimatedCost}
                onChange={handleFormChange}
                error={!form.estimatedCost && activeStep > 0}
                helperText={!form.estimatedCost && activeStep > 0 ? 'Estimated cost is required' : ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Project Start Date"
                name="projectStartDate"
                type="date"
                value={form.projectStartDate}
                onChange={handleFormChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!form.projectStartDate && activeStep > 0}
                helperText={!form.projectStartDate && activeStep > 0 ? 'Start date is required' : ''}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Box>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <Paper
                {...getRootProps()}
                sx={{
                  p: 6,
                  border: '3px dashed',
                  borderColor: isDragActive ? 'primary.main' : 'divider',
                  borderRadius: 4,
                  bgcolor: isDragActive ? alpha(theme.palette.primary.main, 0.08) : 'background.paper',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                  minHeight: '300px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  '&:hover': {
                    borderColor: 'primary.main',
                    bgcolor: alpha(theme.palette.primary.main, 0.08),
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[4],
                  },
                  '&::before': isDragActive ? {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: `radial-gradient(circle at center, ${alpha(theme.palette.primary.main, 0.1)} 0%, transparent 70%)`,
                    animation: 'pulse 2s infinite',
                  } : {},
                }}
              >
                <input {...getInputProps()} />
                <motion.div
                  initial={{ scale: 1 }}
                  animate={{ 
                    scale: isDragActive ? 1.1 : 1,
                    y: isDragActive ? -10 : 0
                  }}
                  transition={{ duration: 0.3 }}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '24px',
                  }}
                >
                  <CloudUploadIcon 
                    sx={{ 
                      fontSize: 80, 
                      color: isDragActive ? 'primary.main' : 'text.secondary',
                      transition: 'all 0.3s ease',
                    }} 
                  />
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        mb: 1,
                        color: isDragActive ? 'primary.main' : 'text.primary',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      {isDragActive
                        ? 'Drop your files here'
                        : 'Drag and drop your files here'}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                      or click to browse
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                        justifyContent: 'center',
                      }}
                    >
                      Supported formats: 
                      <Box component="span" sx={{ color: 'primary.main', fontWeight: 'medium' }}>
                        PDF, DOC, DOCX, PNG, JPG
                      </Box>
                    </Typography>
                  </Box>
                </motion.div>
              </Paper>
            </motion.div>

            {files.length > 0 && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <InsertDriveFileIcon color="primary" />
                    Uploaded Files ({files.length})
                  </Typography>
                  <Grid container spacing={2}>
                    {files.map((file, index) => (
                      <Grid item xs={12} key={index}>
                        <motion.div
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.3, delay: index * 0.1 }}
                        >
                          <Paper
                            sx={{
                              p: 2,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              borderRadius: 2,
                              bgcolor: alpha(theme.palette.background.paper, 0.7),
                              backdropFilter: 'blur(8px)',
                              transition: 'all 0.3s ease',
                              '&:hover': {
                                bgcolor: alpha(theme.palette.background.paper, 0.9),
                                transform: 'translateX(4px)',
                              },
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <InsertDriveFileIcon color="primary" />
                              <Box>
                                <Typography variant="subtitle2">{file.name}</Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {(file.size / 1024 / 1024).toFixed(2)} MB
                                </Typography>
                              </Box>
                            </Box>
                            <Button
                              startIcon={<DeleteIcon />}
                              color="error"
                              onClick={() => handleFileDelete(file)}
                              sx={{
                                '&:hover': {
                                  bgcolor: alpha(theme.palette.error.main, 0.1),
                                },
                              }}
                            >
                              Remove
                            </Button>
                          </Paper>
                        </motion.div>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </motion.div>
            )}
          </Box>
        );
      case 2:
        return renderAnalysisStatus();
      default:
        return null;
    }
  };

  return (
      <Box
        component="main"
        sx={{
        flexGrow: 1,
          bgcolor: 'background.default',
        minHeight: '100vh',
        pt: '84px'
        }}
      >
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 4 }}>
            <IconButton
              onClick={() => navigate(-1)}
              sx={{
                bgcolor: 'background.paper',
                boxShadow: 1,
                '&:hover': { 
                  bgcolor: alpha(theme.palette.primary.main, 0.08),
                  boxShadow: 2,
                },
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Box>
            <Typography variant="h4" gutterBottom>
                New Permit Application
            </Typography>
              <Typography variant="body1" color="text.secondary">
                Project ID: {projectId}
            </Typography>
            </Box>
          </Box>

            <Stepper
              activeStep={activeStep}
              sx={{ mb: 4 }}
              orientation={isMobile ? 'vertical' : 'horizontal'}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Paper sx={{ p: 3, mb: 3 }} ref={reportRef}>
              {isAnalyzing ? renderAnalysisStatus() : renderStepContent(activeStep)}
            </Paper>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            {activeStep > 0 && (
              <Button
                onClick={handleBack}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            )}
              <Button
                variant="contained"
                onClick={activeStep === steps.length - 1 ? handleDownload : handleNext}
              endIcon={activeStep === steps.length - 1 ? <DownloadIcon /> : <ArrowForwardIcon />}
                disabled={isAnalyzing}
              >
              {activeStep === steps.length - 1 ? 'Download Report' : 'Next'}
              </Button>
            </Box>
          </motion.div>
        </Container>
      </Box>
  );
};

export default NewPermit; 